import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  const login = localStorage.getItem("user-login");
  if (!store.getters.isAuthenticated) {
    next();
    return;
  };
  if (ifAuthenticatedWor && login === 'manufacturing_man') {
    next('/manufacture');
    return;
  };
  if (ifAuthenticatedAd && login === 'admin_man') {
    next('/admin');
    return;
  };
  if (ifAuthenticatedMan && login === '9055') {
    next('/designdep');
    return;
  };
  if (ifAuthenticatedDes) {
    next('/designers');
    return;
  };
}

const ifAuthenticatedDes = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.authStatus) {
    next();
    return;
  };
  next('/');
}
const ifAuthenticatedWor = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.authStatus) {
    next();
    return;
  };
  next('/');
}
const ifAuthenticatedAd = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.authStatus) {
    next();
    return;
  };
  next('/');
}
const ifAuthenticatedMan = (to, from, next) => {
  if (store.getters.isAuthenticated && store.getters.authStatus) {
    next();
    return;
  };
  next('/');
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('./views/login.vue'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/designers',
      name: 'designer',
      component: () => import('./views/designers.vue'),
      beforeEnter: ifAuthenticatedDes,
    },
    {
      path: '/manufacture',
      name: 'manufacture',
      component: () => import('./views/manufacture.vue'),
      beforeEnter: ifAuthenticatedWor,
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('./views/admin.vue'),
      beforeEnter: ifAuthenticatedAd,
    },
    {
      path: '/designdep',
      name: 'designdep',
      component: () => import('./views/designdep.vue'),
      beforeEnter: ifAuthenticatedMan,
    }
  ]
});
