import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
  } from "../actions/auth";
  import { USER_REQUEST } from "../actions/user";
  import apiCall from "utils/api";
  
  const state = {
    token: localStorage.getItem("user-token") || "",
    status: localStorage.getItem("user-status") || "",
    login: localStorage.getItem("user-login") || "",
    hasLoadedOnce: false
  };
  
  const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => !!state.status
  };
  
  const actions = {
    [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST);
        apiCall({ url: "auth", data: user, method: "POST" })
          .then(resp => {
            localStorage.setItem("user-token", resp.token);
            localStorage.setItem("user-status", resp.status);
            localStorage.setItem("user-login", resp.username);
            commit(AUTH_SUCCESS, resp);
            dispatch(USER_REQUEST);
            resolve(resp);
          })
          .catch(err => {
            commit(AUTH_ERROR, err);
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-status");
            localStorage.removeItem("user-login");
            reject(err);
          });
      });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise(resolve => {
        commit(AUTH_LOGOUT);
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-status");
        localStorage.removeItem("user-login");
        resolve();
      });
    }
  };
  
  const mutations = {
    [AUTH_REQUEST]: state => {
      state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = "success";
      state.token = resp.token;
      state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
      state.status = "error";
      state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
      state.token = "";
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  